<template>
  <div class="df-width about">
    <div class="about-header">
      <div class="about-header-title">公司概括</div>
      <div class="about-header-label">创造新价值，成为客户最有价值的伙伴</div>
    </div>
    <div class="about-tab">
      <el-tabs v-model="activeName">
        <el-tab-pane label="公司简介" name="first">
          <div class="about-tab-item about-tab-content">
            <el-image class="about-tab-content-pic" :src="company" fit="cover"></el-image>
            <div class="about-tab-info">
              <div class="about-tab-info-title">珠海市大悦科技有限公司</div>
              <div class="about-tab-info-desc">
                珠海市大悦科技有限公司是一家专注于移动互联网技术研发的国家级高新技术企业。是珠海市云计算与大数据学会副会长单位，是珠海市首批通过国家知识产权贯标体系认证；工信部Hadoop、Openstack高级认证的公司。<br/>
                我们致力于为客户提供移动信息化解决方案；帮助客户建设大数据分析能力。我们的目标是为客户创造新价值，成为客户最有价值的伙伴。
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="荣誉证书" name="second">
          <div class="about-tab-item about-tab-honor">
            <div class="about-honor-item">
              <div class="about-honor-box">
                <el-image class="about-honor-item-pic" :src="honor1" fit="cover"></el-image>
              </div>
              <div class="about-honor-item-title">客户管理系统</div>
            </div>
            <div class="about-honor-item">
              <div class="about-honor-box">
                <el-image class="about-honor-item-pic" :src="honor2" fit="cover"></el-image>
              </div>
              <div class="about-honor-item-title">童悦乐园幼教管理平台</div>
            </div>
            <div class="about-honor-item">
              <div class="about-honor-box">
                <el-image class="about-honor-item-pic" :src="honor3" fit="cover"></el-image>
              </div>
              <div class="about-honor-item-title">所乐B2C在线销售管理系统</div>
            </div>
            <div class="about-honor-item">
              <div class="about-honor-box">
                <el-image class="about-honor-item-pic" :src="honor4" fit="cover"></el-image>
              </div>
              <div class="about-honor-item-title">“旗帜”大数据党建系统</div>
            </div>
          </div>
          <div class="about-tab-page">
            <el-pagination :page-size="24" background layout="pager" :total="4"></el-pagination>
          </div>
        </el-tab-pane>
        <el-tab-pane label="企业文化" name="third">
          <div class="calture-title">
            愿景、使命、价值观决定结局
          </div>
          <div class="calture-desc">
            我们是一群对客户充满激情和责任感的人，我们强调团队，提倡共赢，善于学习和拼搏，勇于创造和坚持， 满足网络用户的应用需求，为信息化建设不断贡献自己的力量！
          </div>
          <div class="calture-card">
            <div class="calture-card-item" :style="{backgroundImage: 'url(' + cal1 + ')'}">
              <div class="calture-card-txt">企业愿景</div>
              <div class="calture-card-desc">Enterprise vision</div>
              <div class="calture-card-content">我们的目标是为客户创造新价值，成为客户最有价值的伙伴</div>
            </div>
            <div class="calture-card-item" :style="{backgroundImage: 'url(' + cal2 + ')'}">
              <div class="calture-card-txt">企业使命</div>
              <div class="calture-card-desc">Corporate mission</div>
              <div class="calture-card-content">专注移动互联网技术研发</div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="新闻媒体" name="fourth">
          <div class="news">
            <div class="news-item">
              <div class="news-item-title">用于快速跨设备网站测试的工具（一）</div>
              <div class="news-item-desc">创建网站时，请务必记住，它不仅需要在您创建网站的设备上正常工作并看起来美观，而且在所有其他设备上也可能会使用。因此需要您的网站在上线之前在不同的设备上进行测试。</div>
              <div class="news-item-date">2022年03月02日</div>
              <div class="news-item-line"></div>
            </div>
            <div class="news-item">
              <div class="news-item-title">用于快速跨设备网站测试的工具（一）</div>
              <div class="news-item-desc">创建网站时，请务必记住，它不仅需要在您创建网站的设备上正常工作并看起来美观，而且在所有其他设备上也可能会使用。因此需要您的网站在上线之前在不同的设备上进行测试。</div>
              <div class="news-item-date">2022年03月02日</div>
              <div class="news-item-line"></div>
            </div>
            <div class="news-item">
              <div class="news-item-title">用于快速跨设备网站测试的工具（一）</div>
              <div class="news-item-desc">创建网站时，请务必记住，它不仅需要在您创建网站的设备上正常工作并看起来美观，而且在所有其他设备上也可能会使用。因此需要您的网站在上线之前在不同的设备上进行测试。</div>
              <div class="news-item-date">2022年03月02日</div>
              <div class="news-item-line"></div>
            </div>
            <div class="news-item">
              <div class="news-item-title">用于快速跨设备网站测试的工具（一）</div>
              <div class="news-item-desc">创建网站时，请务必记住，它不仅需要在您创建网站的设备上正常工作并看起来美观，而且在所有其他设备上也可能会使用。因此需要您的网站在上线之前在不同的设备上进行测试。</div>
              <div class="news-item-date">2022年03月02日</div>
              <div class="news-item-line"></div>
            </div>
            <div class="news-item">
              <div class="news-item-title">用于快速跨设备网站测试的工具（一）</div>
              <div class="news-item-desc">创建网站时，请务必记住，它不仅需要在您创建网站的设备上正常工作并看起来美观，而且在所有其他设备上也可能会使用。因此需要您的网站在上线之前在不同的设备上进行测试。</div>
              <div class="news-item-date">2022年03月02日</div>
              <div class="news-item-line"></div>
            </div>
            <div class="news-item">
              <div class="news-item-title">用于快速跨设备网站测试的工具（一）</div>
              <div class="news-item-desc">创建网站时，请务必记住，它不仅需要在您创建网站的设备上正常工作并看起来美观，而且在所有其他设备上也可能会使用。因此需要您的网站在上线之前在不同的设备上进行测试。</div>
              <div class="news-item-date">2022年03月02日</div>
              <div class="news-item-line"></div>
            </div>
          </div>
          <div class="about-tab-page">
            <el-pagination :page-size="6" background layout="pager" :total="0"></el-pagination>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
  <div v-if="activeName === 'third'" class="calture">
    <div class="df-width">
      <div class="calture-tab-title">我们的价值</div>
      <div class="calture-tab">
        <div class="calture-tab-line">
          <div class="calture-tab-item-1">
            <div class="calture-tab-item-txt">专注</div>
            <div class="calture-tab-item-desc">大悦科技始终视服务品质与营销质量为发展命脉。专注服务是我们一贯的 追求。</div>
          </div>
          <div class="calture-tab-item-2" :style="{backgroundImage: 'url(' + ct1 + ')'}"></div>
          <div class="calture-tab-item-1">
            <div class="calture-tab-item-txt">深入</div>
            <div class="calture-tab-item-desc">大悦科技始终视服务品质与营销质量为发展命脉。专注服务是我们一贯的 追求。</div>
          </div>
          <div class="calture-tab-item-2" :style="{backgroundImage: 'url(' + ct2 + ')'}"></div>
        </div>
        <div class="calture-tab-line">
          <div class="calture-tab-item-2" :style="{backgroundImage: 'url(' + ct3 + ')'}"></div>
          <div class="calture-tab-item-1">
            <div class="calture-tab-item-txt">实效</div>
            <div class="calture-tab-item-desc">大悦科技始终视服务品质与营销质量为发展命脉。专注服务是我们一贯的 追求。</div>
          </div>
          <div class="calture-tab-item-2" :style="{backgroundImage: 'url(' + ct4 + ')'}"></div>
          <div class="calture-tab-item-1">
            <div class="calture-tab-item-txt">创新</div>
            <div class="calture-tab-item-desc">大悦科技始终视服务品质与营销质量为发展命脉。专注服务是我们一贯的 追求。</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'About',
  data () {
    return {
      activeName: 'first',
      company: require('@/assets/images/company.png'),
      honor1: require('@/assets/images/khglxt.png'),
      honor2: require('@/assets/images/tylyyj.png'),
      honor3: require('@/assets/images/slb2c.png'),
      honor4: require('@/assets/images/qzdsj.png'),
      cal1: require('@/assets/images/qyyj.png'),
      cal2: require('@/assets/images/qysm.png'),
      ct1: require('@/assets/images/ct1.png'),
      ct2: require('@/assets/images/ct2.png'),
      ct3: require('@/assets/images/ct3.png'),
      ct4: require('@/assets/images/ct4.png')
    }
  }
}
</script>

<style scoped>
  .about-header {
    text-align: center;
    margin-top: 60px;
  }
  .about-header-title {
    font-size: 48px;
    color: var(--color-text);
  }
  .about-header-label {
    font-size: 16px;
    margin-top: 20px;
    color: var(--color-text);
  }
  .about-tab {
    margin-top: 60px;
    margin-bottom: 80px;
  }
  :deep(.el-tabs__nav) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    float: none;
    height: 80px;
  }
  :deep(.el-tabs__nav-wrap::after) {
    height: 0;
  }
  :deep(.el-tabs__active-bar) {
    height: 0;
  }
  :deep(.el-tabs__item) {
    width: 290px;
    height: 60px;
    text-align: center;
    border: 1px solid #808080;
    padding: 0;
    font-size: 24px;
    color: var(--color-text);
    line-height: 60px;
    font-weight: normal;
  }
  :deep(.el-tabs__item.is-active, .el-tabs__item:hover) {
    color: #fff;
    background-color: var(--default-color);
    border-color: var(--default-color);
  }
  :deep(.el-tabs__item.is-active::after) {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    top: 58px;
    transform: translate(-50%, 0);
    width: 0;
    border-bottom: 15px solid transparent;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: 10px solid var(--default-color);
  }
  .about-tab-item {
    margin-top: 25px;
  }
  .about-tab-content {
    display: flex;
  }
  .about-tab-content-pic {
    width: 590px;
    margin-right: 20px;
  }
  .about-tab-info {
    flex: 1;
  }
  .about-tab-info-title {
    color: var(--color-text);
    font-size: 30px;
    padding-top: 28px;
  }
  .about-tab-info-desc {
    font-size: var(--font-size);
    color: var(--color-text);
    margin-top: 30px;
    line-height: 24px;
  }
  .about-tab-honor {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  .about-honor-item {
    width: 180px;
    margin-right: 24px;
    margin-bottom: 20px;
    cursor: pointer;
  }
  .about-honor-item:nth-child(6) {
    margin-right: 0;
  }
  .about-honor-box {
    width: 180px;
    height: 250px;
    padding: 8px 5px;
    border: 1px solid #ccc;
  }
  .about-honor-item-title {
    font-size: 16px;
    color: var(--color-text);
    margin-top: 10px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .about-tab-page {
    text-align: center;
    margin-top: 40px;
  }
  :deep(.el-pagination.is-background .el-pager li:not(.disabled)) {
    background-color: #fff;
    color: #ccc;
    border: 1px solid #ccc;
    font-size: 18px;
    font-weight: normal;
  }
  :deep(.el-pagination.is-background .el-pager li:not(.disabled).active, .el-pagination.is-background .el-pager li:not(.disabled):hover) {
    background-color: #fff;
    color: var(--default-color);
    border: 1px solid var(--default-color);
  }
  .calture-title {
    color: var(--color-text);
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    margin-top: 50px;
  }
  .calture-desc {
    color: var(--color-text);
    font-size: 16px;
    text-align: center;
    width: 700px;
    margin: auto;
    margin-top: 40px;
  }
  .calture-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
  }
  .calture-card-item {
    width: 580px;
    height: 200px;
    color: #fff;
    font-size: 16px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  .calture-card-txt {
    font-size: 30px;
    padding: 30px 10px 0;
  }
  .calture-card-desc {
    padding-left: 10px;
    margin-top: 8px;
  }
  .calture-card-content {
    margin-top: 30px;
    padding: 0 10px;
    line-height: 32px;
  }
  .calture {
    background-color: #f5f5f5;
    padding-bottom: 80px;
  }
  .calture-tab-title {
    padding: 40px 0;
    color: var(--color-text);
    font-size: 30px;
    text-align: center;
  }
  .calture-tab-line {
    display: flex;
    align-items: center;
  }
  .calture-tab-item-1, .calture-tab-item-2 {
    flex: 1;
    height: 180px;
  }
  .calture-tab-item-1 {
    background-color: #fff;
  }
  .calture-tab-item-2 {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  .calture-tab-item-txt {
    padding: 30px 30px 28px;
    font-size: 30px;
    color: var(--color-text);
  }
  .calture-tab-item-desc {
    font-size: 16px;
    color: var(--color-text);
    padding: 0 30px;
    line-height: 24px;
  }
  .news {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-top: 50px;
  }
  .news-item {
    margin: 0 5px;
    width: 580px;
    height: 300px;
    cursor: pointer;
  }
  .news-item:hover {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  }
  .news-item-title {
    padding: 60px;
    padding-bottom: 30px;
    color: var(--color-text);
    font-size: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .news-item-desc {
    padding: 0 60px;
    color: var(--color-text);
    font-size: 14px;
    line-height: 24px;
    text-align: justify;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .news-item-date {
    padding: 30px 60px 20px;
    color: var(--color-text);
    font-size: 14px;
  }
  .news-item-line {
    margin: 0 60px;
    height: 1px;
    background-color: var(--color-text);
  }
</style>
